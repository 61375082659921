<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{$t('MenuPainelGerente.listCustomersConfiguration')}}</v-card-title>
          <v-card-text>
            <v-select
              @change="changeCustomer"
              return-object
              v-model="client"
              :items="listCustomers"
              item-text="customer_Name"
              item-value="customer_Id"
              :label="$t('MenuPainelGerente.clientName')"
              required
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-list>
            <v-list-item
              v-if="panelFunction != undefined && panelFunction.functionality_Level > 2"
              @click="
                $route.name != 'painelCliente'
                  ? $router.push({ name: 'painelCliente' })
                  : false
              "
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.panel') }}</v-list-item-title>
            </v-list-item>
      
            <v-list-item
              v-if="attendenceFunction != undefined && attendenceFunction.functionality_Level > 2"
              @click="$router.push({ name: 'atendimento' })" link>
              <v-list-item-icon>
                <v-icon>mdi-chat-processing</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.atendimento') }}</v-list-item-title>
            </v-list-item>
      
            <v-list-item
              v-if="campaignsFunction != undefined && campaignsFunction.functionality_Level > 2"
              @click="
                $route.name != 'listCampaign'
                  ? $router.push({ name: 'listCampaign' })
                  : false
              "
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-bell</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.campaign') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="operatorsFunction != undefined && operatorsFunction.functionality_Level > 2"
              @click="
                $route.name != 'listaOperadores'
                  ? $router.push({ name: 'listaOperadores' })
                  : false
              "
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-headset</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.operators') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="campaignsFunction != undefined && campaignsFunction.functionality_Level > 2"
              @click="
                $route.name != 'listGroup'
                  ? $router.push({ name: 'listGroup' })
                  : false
              "
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.groups') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="reportsFunction != undefined && reportsFunction.functionality_Level > 2"
              @click="
                $route.name != 'reports'
                  ? $router.push({ name: 'reports' })
                  : false
              "
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-chart-areaspline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.report') }}</v-list-item-title>
            </v-list-item>
                  
            <v-list-item
            v-if="flowsFunction != undefined && flowsFunction.functionality_Level > 2"
              @click="
                $router.push(
                  {
                    name: 'fluxo',
                  },
                  () => {}
                )
              "
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-sitemap</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.flows') }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="templateFunction != undefined && templateFunction.functionality_Level > 2"
              @click="
                $route.name != 'messagesTemplate'
                  ? $router.push({ name: 'messagesTemplate' })
                  : false
              "
              link>
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon> 
              <v-list-item-title>{{ $t('MenuPainelGerente.templates') }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="reasonFunction != undefined && reasonFunction.functionality_Level > 2"
              @click="
                $route.name != 'reasons'
                  ? $router.push({ name: 'reasons' })
                  : false
              "
              link>
              <v-list-item-icon>
                <v-icon>mdi-alpha-r-box</v-icon>
              </v-list-item-icon> 
              <v-list-item-title>{{ $t('MenuPainelGerente.Reasons') }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="hsmFunction != undefined && hsmFunction.functionality_Level > 2"
              @click="
                $route.name != 'execHSM'
                  ? $router.push({ name: 'executeHsm'})
                  : false
              "
              link>
              <v-list-item-icon>
                <v-icon>mdi-send-clock </v-icon>
              </v-list-item-icon> 
              <v-list-item-title>{{ $t('MenuPainelGerente.HSM') }}</v-list-item-title>
            </v-list-item>

            <div v-if=false>
              <v-list-item v-if="flowsFunction != undefined && flowsFunction.functionality_Level > 2"
                @click="
                  $route.name != 'loadFiles'
                    ? $router.push({ name: 'loadFiles' })
                    : false
                "
                link>
                <v-list-item-icon>
                  <v-icon>mdi-file-upload</v-icon>
                </v-list-item-icon> 
                <v-list-item-title>{{ $t('MenuPainelGerente.loadFiles') }}</v-list-item-title>
              </v-list-item>
            </div>
            
            <!--<v-list-item v-if="ticketsFunction != undefined && ticketsFunction.functionality_Level >= 2"-->
              <v-list-item v-if="true"
              @click="
                $route.name != 'tickets'
                  ? $router.push({ name: 'tickets'})
                  : false
              "
              link>
              <v-list-item-icon>
                <v-icon>mdi-bus</v-icon>
              </v-list-item-icon> 
              <v-list-item-title>{{ $t('MenuPainelGerente.tickets') }}</v-list-item-title>
            </v-list-item>

            <v-list-item class="mt-16" link @click=exit>
              <v-list-item-icon>
                <v-icon>mdi-exit-run</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('MenuPainelGerente.quit') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONNECTIONSIGNALR } from "../plugins/signalR"

export default {
  data() {
    return {
      listFunctionalities: [],

      panelFunction: [],
      attendenceFunction: [],
      campaignsFunction: [],
      operatorsFunction: [],
      attendenceGroupFunction: [],
      reportsFunction: [],
      flowsFunction: [],
      templateFunction: [],
      hsmFunction: [],
      reasonFunction: [],
      ticketsFunction: [],

      reportsPaymentsFunction: [],
      listCustomers: [],
      client: [],
    };
  },
  computed: {
    ...mapGetters(['getListDataInformation']),
    ...mapGetters(['getListUserFunctions'])
  },
  methods: {
    async exit(){
      await CONNECTIONSIGNALR.stop();
      this.logout();
    },
    logout() {
      localStorage.removeItem("authUser");
      localStorage.removeItem("convAtual");
      this.$store.commit('setInitialData')
      this.$router.push({ name: "home" });
    },
    setInitialVars() {
      this.listFunctionalities = this.getListUserFunctions;

      //Set permissions and in the watch to
      this.panelFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Painel");
      this.attendenceFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence");
      this.campaignsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Campaign" );
      this.operatorsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "User");
      this.attendenceGroupFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence Group");
      this.reportsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Report");
      this.flowsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Flow");
      this.templateFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Template");
      this.hsmFunction = this.getListUserFunctions.find(func => func.functionality_Name == "HSM");
      this.reportsPaymentsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Payment");
      this.reasonFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Reasons");
      this.ticketsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Ticket");
      this.listCustomers = this.getListDataInformation;

      if (this.$store.state.currentCustomer == null) {
        if(this.getListDataInformation.length > 1) {
          this.client = []
        } else if(this.getListDataInformation.length == 1) {
          this.client = this.getListDataInformation[0];
          this.$store.commit('setCurrentCostumer', this.client);
          localStorage.setItem("customer_Id", this.client.customer_Id);
          this.changeCustomer()
        } else if(this.getListDataInformation.length == 0) {
          this.client = []
        }
      } else {
        this.client = this.$store.state.currentCustomer;
        this.changeCustomer()
      }

    },
    changeCustomer() {
      localStorage.setItem("customer_Id", this.client.customer_Id);
      this.$store.commit('setCurrentCostumer', this.client);
      this.$store.dispatch('listGroup', localStorage.getItem("customer_Id"));
      this.$store.dispatch('campaignList', localStorage.getItem("customer_Id"));
      this.$store.dispatch('listStepType', localStorage.getItem("customer_Id"));
      this.$store.dispatch('listMessagesTemplates', localStorage.getItem("customer_Id"));
      this.$store.dispatch('listLoadFiles', localStorage.getItem("customer_Id"));
      this.$store.dispatch('ListReason', localStorage.getItem("customer_Id"));
      this.$store.dispatch('ListSubreason', localStorage.getItem("customer_Id"));
    }
  },
  watch: {
    getListUserFunctions() {
      this.panelFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Painel");
      this.attendenceFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence");
      this.campaignsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Campaign" );
      this.operatorsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "User");
      this.attendenceGroupFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Attendence Group");
      this.reportsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Report");
      this.flowsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Flow");
      this.templateFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Template");
      this.hsmFunction = this.getListUserFunctions.find(func => func.functionality_Name == "HSM");
      this.reasonFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Reasons");
      this.ticketsFunction = this.getListUserFunctions.find(func => func.functionality_Name == "Ticket");
    },
    getListDataInformation() {
      this.listCustomers = this.getListDataInformation
    }
  },
  created() {
    if (this.$store.state.permissions.length == 0) {
          setTimeout(() => {
              this.setInitialVars();
          }, 1000);
      }
      else {
          this.setInitialVars();
      }
  }
};
</script>

<style>
</style>