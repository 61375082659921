import Vue from 'vue'
import VueRouter from 'vue-router'

// import Menu from '@/components/MyMenu.vue'
import Home from '@/views/MyHome.vue'
import Chat from '@/views/MyAtendimento.vue'
import MenuPainelGerente from '@/views/PainelGerente.vue'
import Fluxo from '@/views/MyFluxo.vue'
import Reports from '@/views/MyReport.vue'

import ListaOperadores from '@/components/ListaOperadores.vue'
import ListCampaign from '@/components/ListCampaign.vue'
import Campaign from '@/components/NovoCampaign.vue'
import Cadastro from '@/components/NovoCadastro.vue'
import PainelCliente from '@/components/PainelCliente.vue'
import ViewFluxo from '@/components/ViewFluxo.vue'
import CreateFluxo from '@/components/CreateFluxo.vue'
//import ReportsClientMenu from '@/components/ReportsClientMenu.vue'
import ReportsClient from '@/components/ReportsClient.vue'
import ListGroup from '@/components/ListGroup.vue'
import Group from '@/components/NewGroup.vue'
import MessageTemplate from '@/components/MessagesTemplate.vue'
import NewTemplate from '@/components/NewMessageTemplate.vue'
import LoadFiles from '@/components/LoadFiles.vue'
import NewFile from '@/components/NewLoadFile.vue'
import HsmComponent from '@/components/ExecHSM.vue'
import ListReason from '@/components/ListReason.vue'
import NewReason from '@/components/NewReason.vue'
import Tickets from '@/components/TicketsList.vue'
import TicketDetails from '@/components/TicketDetails.vue'

import Error404 from '@/views/ErroR404.vue'

Vue.use(VueRouter)

const routes = [{
  name: 'home',
  path: '/home',
  props: true,
  component: Home,
  meta: { title: 'Home - ATH' }
},
{
  name: 'atendimento',
  path: '/',
  component: Chat,
},
{
  name: 'painel',
  path: '/painel',
  component: MenuPainelGerente,
  children: [
    {
      name: 'listaOperadores',
      path: '/painel/lista-operadores',
      component: ListaOperadores
    },
    {
      name: 'listCampaign',
      path: '/painel/list-campaign',
      component: ListCampaign
    },
    {
      name: 'campaign',
      path: '/painel/campaign',
      component: Campaign
    },
    {
      name: 'updateCampaign',
      path: '/painel/edit-campaign/:id',
      props: true,
      component: Campaign
    },
    {
      name: 'listGroup',
      path: '/painel/list-group',
      component: ListGroup
    },
    {
      name: 'group',
      path: '/painel/group',
      component: Group
    },
    {
      name: 'updateGroup',
      path: '/painel/edit-group/:id',
      props: true,
      component: Group
    },
    {
      name: 'cadastro',
      path: '/painel/cadastro',
      component: Cadastro
    },
    {
      name: 'atualizar',
      path: '/painel/edit-user/:id',
      props: true,
      component: Cadastro
    },
    {
      name: 'painelCliente',
      path: '/painel/cliente',
      component: PainelCliente
    },
    {
      name: 'messagesTemplate',
      path: '/painel/templates',
      component: MessageTemplate
    },
    {
      name: 'newMessageTemplate',
      path: '/painel/templates/new',
      component: NewTemplate
    },
    {
      name: 'updateMessageTemplate',
      path: '/painel/templates/edit/:id',
      props: true,
      component: NewTemplate
    },
    {
      name: 'loadFiles',
      path: '/painel/files',
      component: LoadFiles
    },
    {
      name: 'newUploadFile',
      path: '/painel/files/new',
      component: NewFile
    },
    {
      name: 'updateFile',
      path: '/painel/files/new/:id',
      props: true,
      component: NewFile
    },
    {
      name: 'executeHsm',
      path: '/painel/hsm',
      props: false,
      component: HsmComponent
    },
    {
      name: 'reasons',
      path: '/painel/reasons',
      component: ListReason
    },
    {
      name: 'updateReason',
      path: '/painel/reason/new/:id',
      props: true,
      component: NewReason
    },
    {
      name: 'newReason',
      path: '/painel/reason/new',
      component: NewReason
    },
    {
      name: 'tickets',
      path: '/painel/tickets',
      component: Tickets
    },
    {
      name: 'ticket-details',
      path: '/painel/ticket-details/:id',
      props: true,
      component: TicketDetails
    }
  ]
},
{
  name: 'reports',
  path: '/reports',
  component: Reports,
  children: [
    {
      name: 'update-reports',
      path: '/reports/update',
      props: true,
      component: ReportsClient
    }
  ]
},
{
  name: 'fluxo',
  path: '/fluxo',
  component: Fluxo,
  children: [
    {
      name: 'view-fluxo',
      path: '/fluxo/view/:id-:name-:channel-:flowMessage-:startDate-:endDate-:flowExitMessage-:flowLeaveMessage',
      props: true,
      component: ViewFluxo
    },
    {
      name: 'create-fluxo',
      path: '/fluxo/create',
      props: true,
      component: CreateFluxo
    },
    {
      name: 'update-fluxo',
      path: '/fluxo/create:id-:name-:channel-:message-:startDate-:endDate-:flowExitMessage-:flowLeaveMessage',
      props: true,
      component: CreateFluxo
    },
  ]
},
{
  name: 'error404',
  path: '/error404',
  component: Error404
},
{
  path: '*',
  redirect: '/error404'
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name != "home" && to.name != "error404" && !localStorage.getItem('authUser')) { // se o user n estiver tentando entrar no /home e n estiver com authUSer ele se redirecionado ao /home, a unica pagina que pode ser acessada sem o authUser é o home
    next({ name: 'home' })  
  }
  else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name.toUpperCase()} - ATH`;
  next();
});
export default router
