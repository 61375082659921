<template>
    <div v-if="show" class="modal-overlay">
      <div class="modal-content">
        <slot name="header">{{ $t('ConfirmationModal.confirmation') }}</slot>
        <slot>{{ $t('ConfirmationModal.confirmationQuestion') }}</slot>
        <div class="modal-buttons">
          <button @click="confirm">{{ $t('ConfirmationModal.yesButton') }}</button>
          <button @click="cancel">{{ $t('ConfirmationModal.noButton') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean,
    },
    methods: {
      confirm() {
        this.$emit('confirm');
      },
      cancel() {
        this.$emit('cancel');
      },
    },
  };
  </script>
  
  <style scoped>
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  .modal-buttons button {
    margin-left: 10px; 
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  .modal-buttons button:first-child { 
    background-color: #dc3545; 
    color: white;
  }
  .modal-buttons button:last-child { 
    background-color: #6c757d; 
    color: white;
  }
  </style>