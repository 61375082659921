<template>
  <div class="ticket-container" v-if="ticketData.length > 0">    
    <h1 class="title-text">{{ $t('TicketDetail.ticketsBoarding') }}</h1>
      <div class="ticket" v-for="(ticket, index) in ticketData" :key="index">
        <div class="ticket-body" v-if="ticket.reserve_data_var.confirmacao && ticket.reserve_data_var.confirmacao.daBpe">
          <div class="travel-details">
            <p>{{ $t('TicketDetail.date') }}: <b>{{formatDate(ticket.reserve_data_var.confirmacao.assento.data) }}</b></p>
            <p>{{ $t('TicketDetail.travelLine') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.linha }}</b></p>
          </div>
          <div class="travel-details">
            <p>{{ $t('TicketDetail.travelClass') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.classe }}</b></p>
            <p>{{ $t('TicketDetail.travelType') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.tipoServico }}</b></p>
          </div>
          <div class="travel-details">
            <p>{{ $t('TicketDetail.passenger') }}: <b>{{ ticket.reserve_data_var.confirmacao.nome }}</b></p>
            <p>{{ $t('TicketDetail.doc') }}: <b>{{ ticket.reserve_data_var.confirmacao.documento }}</b></p>
            <p>{{ $t('TicketDetail.armchair') }}: <b>{{ ticket.reserve_data_var.confirmacao.assento.assento }}</b></p>
            <p>{{ $t('TicketDetail.prefix') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.prefixo }}</b></p>
          </div>        
          <div class="travel-details">
            <p>{{ $t('TicketDetail.tax') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.tarifa) }}</b></p>
            <p>{{ $t('TicketDetail.toll') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.pedagio) }}</b></p>
            <p>{{ $t('TicketDetail.boardingTax') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.taxaEmbarque) }}</b></p>
            <p>{{ $t('TicketDetail.mandatoryInsurance') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.seguro) }}</b></p>
          </div>
          <div class="travel-details">
            <p>{{ $t('TicketDetail.others') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.outros) }}</b></p>
            <p>{{ $t('TicketDetail.totalValue') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.valorTotal) }}</b></p>
            <p>{{ $t('TicketDetail.discount') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.desconto) }}</b></p>
            <p>{{ $t('TicketDetail.paymentValue') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.valorPagar) }}</b></p>
          </div>
          <div class="travel-details">
            <p>{{ $t('TicketDetail.discountType') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.tipoDesconto }}</b></p>
            <p>{{ $t('TicketDetail.platform') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.plataforma }}</b></p>
            <p>{{ $t('TicketDetail.clientEmailHeader') }}: <b>{{ ticketDataProp.client_email_var }}</b></p>
            <p>{{ $t('TicketDetail.paymentStatusHeader') }}: <b>{{ ticketDataProp.order_status_description_var }}</b></p>
          </div>
          <div class="travel-details">
            <p>{{ $t('TicketDetail.paymentType') }}: <b>{{ ticket.reserve_data_var.confirmacao.daBpe.formaPagamento }}</b></p>
            <p>{{ $t('TicketDetail.change') }}: <b> {{ formatPrice(ticket.reserve_data_var.confirmacao.daBpe.troco) }}</b></p>
          </div>
          <div class="travel-details">
            <p>{{ $t('TicketDetail.locator') }}: <b>{{ ticket.reserve_data_var.confirmacao.localizador }}</b></p>
          </div>
        </div>
        <div v-else-if="ticket.reserve_data_var.confirmacao" class="ticket-body-error" >
          <div class="ticket-error-detail">
            <h4>{{ $t('TicketDetail.notEmitted') }}</h4>
            <p></p>
            <p>{{ $t('TicketDetail.notEmittedMessage') }}</p>
          </div>
          <div class="ticket-error-detail">
            <p>{{ $t('TicketDetail.passenger') }}: <b>{{ ticket.reserve_data_var.confirmacao.nome }}</b></p>
            <p>{{ $t('TicketDetail.locator') }}: <b>{{ ticket.reserve_data_var.confirmacao.localizador }}</b></p>
          </div>  
          <button class="copy-button" @click="handleCopy(ticket.reserve_data_var.confirmacao.localizador, ticket.reserve_data_var.confirmacao.nome, index)">
            {{ $t('TicketDetail.copy') }}
          </button>
          <div class="copy-success" v-if="showCopySuccess[index]">
            Localizador copiado com sucesso!
          </div>
        </div>
    </div>
    
    <ConfirmationModal :show="showModal" @confirm="confirmarCancelamento" @cancel="showModal = false">
        <template #header>
          {{ $t('TicketDetail.cancelConfirmation') }}
        </template>
    </ConfirmationModal>
    <SuccessModal :show="showSuccessModal" @close="showSuccessModal = false">
        <template #header>
          {{ $t('TicketDetail.cancelConcluded') }}
        </template>
    </SuccessModal>
    <ImageModal :show="this.showImageModal" :imageUrls="imageUrls" @close="showImageModal = false" />
    <button class="back-button" @click="goBack">Voltar</button>
    <button @click="openImageModalWithAllImages" class="view-all-images-button">
      {{ $t('TicketDetail.imagesBpe') }}
    </button>
    <button class="cancel-button" v-if="ticketDataProp.order_status_description_var === 'Pago'" @click="cancelarTicket()">{{ $t('TicketDetail.cancel') }}</button>        
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmationModal from './generic_components/ConfirmationModal.vue';
import SuccessModal from './generic_components/SuccessModal.vue';
import ImageModal from './generic_components/ImageModalBpe.vue';
import moment from 'moment';

export default {
  props:{
    id: {
    required: true
    },
    ticketDataProp: {
      type: Object,
      default: () => ({}) 
    }
  },
  components:{
    ConfirmationModal,
    SuccessModal,
    ImageModal
  },
  data() {
    return {
      ticketData: [],
      ticketParsedReserve:[],
      detailParams:{
        Customer_Id: '',
        Id:''
      }, 
      showModal: false, 
      showSuccessModal: false,
      showCopySuccess: [],
      ticketToCancel: '',
      showImageModal: false,
      imageUrls: []
    };
  },
  methods: {
    cancelarTicket() {
      this.ticketToCancel = this.ticketData[0].order_id_bi; 
      this.showModal = true;       
    },
    formatDate(dateString) {
      return moment(dateString).format('DD/MM/YYYY');
    },
    formatPrice(value) {
      const formattedValue = parseFloat(value).toFixed(2);
      return parseFloat(formattedValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    confirmarCancelamento() {
      if (this.ticketToCancel) {         
          this.$store.dispatch('cancelTripTicket', this.ticketToCancel)
            .then(() => {
              this.showSuccessModal = true; 
            })
            .catch(() => {
            })
            .finally(() => {
              this.showModal = false;     
              this.ticketToCancel = null; 
            });
        }
    },
    async getTicketDetail(){
      this.detailParams.Customer_Id = localStorage.getItem("customer_Id");
      this.detailParams.Id = this.id;
      
      await this.$store.dispatch('getTripDetails', this.detailParams);
      this.ticketData = this.getTicketDetails;      
      this.getTicketDetails.map(ticket => {
        this.ticketParsedReserve.push(JSON.parse(ticket.reserve_data_var));
      })
      this.ticketData.forEach((ticket, index) => {
        ticket.reserve_data_var = this.ticketParsedReserve[index];
      });
      this.showErrorModal = this.ticketData.map(() => false);
      this.showCopySuccess = this.ticketData.map(() => false);
    },
    goBack() {
      this.$router.go(-1);
    },
    copyText(localizador, nome) {
      navigator.clipboard.writeText(`Nome: ${nome} \nLocalizador: ${localizador}`)
        .then(() => {
          this.$emit('copy-success'); 
        })
        .catch((err) => {
          console.error('Falha ao copiar:', err);
        });
    },
    handleCopySuccess(index) {
      this.$set(this.showCopySuccess, index, true);

      setTimeout(() => {
        this.$set(this.showCopySuccess, index, false);
      }, 3000);
    },
    handleCopy(localizador, nome, index) {
      this.copyText(localizador, nome);
      this.handleCopySuccess(index);
    },
    openImageModalWithAllImages() {
      const uniqueGuids = new Set();
      this.imageUrls = this.ticketData.flatMap(ticket => {
        if (ticket.guid_img) {
          const guids = ticket.guid_img.replace(/;$/, '').split(';');
          return guids.filter(guid => {
            if (!uniqueGuids.has(guid)) {
              uniqueGuids.add(guid);
              return true; 
            }
            return false;
          }).map(guid => `${process.env.VUE_APP_ENV_API_IMAGES_BPE}${guid}`);
        }
        return [];
      });
      setTimeout(() => {
      this.showImageModal = true;
    }, 0);
    }
  },
  computed: {
    ...mapGetters(['getTicketDetails']), 
     
  },
  async created() {
    await this.getTicketDetail();  
  },
  mounted() {
    this.ticketData.forEach((_, index) => {
    this.$set(this.showCopySuccess, index, false); 
  });
},
};
</script>

<style scoped>
.ticket-container {
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; 
}

.ticket {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.ticket-header {
  display: flex; 
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%; 
}

.ticket-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cancel-button {
 
  background-color: #dc3545; 
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left:auto;
  margin: 0 5px;
}

.ticket-title {
  text-align: center;
}

.company-info {
  text-align: left;
}

h1 {
  text-align: center; 
  margin-bottom: 20px;
}

.travel-details{
  width: calc(50% - 10px); 
  margin: 5px;
}

.company-info p {
  font-size:9px;
  margin: 0;
}

.ticket-title h1 {
  font-size: 15px;
  margin: 0;
  font-weight: bold;
}

.ticket-body p {
  margin: 3px 0;
}

.ticket-body b {
  font-weight: bold;
}

.payment-details p {
  margin: 3px 0;
}

.ticket-info {
  border-radius: 5px; 
  padding: 10px;       
  margin-bottom: 10px;
  display: flex;        
  flex-wrap: wrap;      
  gap: 10px;            
}

.travel-details-prop {
             
  min-width: 200px;     
}

.travel-details-prop p {
  font-size: 12px;
  margin: 5px 2; 
}



.title-text{
  font-size: 18px;
}

.copy-success {
  text-align: center;
  color: rgb(21, 98, 21); 
}

.ticket-body-error {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  text-align: center;
}

.copy-button {
  background-color: #007bff; 
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 5px; 
  margin-bottom: 10px;
}

.ticket-error-detail{
  width: auto;        
  margin: 1px;
}

.view-all-images-button {
  background-color: #007bff; 
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;  
  margin: 0 5px;
}

.back-button {
  background-color: #007bff; 
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
  margin: 0 5px;
}

</style>
