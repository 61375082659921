<template>
  <div class="filter-container">
    <div class="filter-wrapper">
      <div class="filter-label">{{ $t('TicketList.filters') }}</div>
      <div class="filter-row">
        <v-combobox
          :label="$t('ListTicketsParams.Order_Status')"
          v-model="filter.Order_Status"
          :items="orderStatusOptions"
          item-text="label"
          item-value="value"
          clearable
        ></v-combobox>

        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              :label="$t('ListTicketsParams.Start_Date')"
              v-model="filter.Start_Date"
              readonly
              append-icon="mdi-calendar"
              @click:append="menu = !menu"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.Start_Date" no-title scrollable></v-date-picker>
        </v-menu>

        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              :label="$t('ListTicketsParams.End_Date')"
              v-model="filter.End_Date"
              readonly
              append-icon="mdi-calendar"
              @click:append="menu2 = !menu2"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.End_Date" no-title scrollable></v-date-picker>
        </v-menu>

        <v-text-field
          :label="$t('ListTicketsParams.Cpf_Client')"
          v-model="filter.Cpf_Client"
        />

        <v-btn text @click="getListTickets">{{ $t('TicketList.filter') }}</v-btn>
        <v-btn text @click="clearFilters">{{ $t('TicketList.clean') }}</v-btn>
      </div>
    </div>

    <v-data-table
      data-table-select
      :headers="headers"
      :items="ticketsWithCombinedColumns"
      :sort-by-text="$t(headers.text)"
      hide-default-footer
      class="elevation-1"
    >
    <template v-slot:[`item.origin_destination`]="{ item }">
      {{ item.origin_destination }}
    </template>
    <template v-slot:[`item.date_hour_travel`]="{ item }">
      {{ item.date_hour_travel }}
    </template>
    <template v-slot:[`item.detailTicket`]="props">
      <v-btn v-if="props.item.order_status_description_var === 'Pago' || props.item.order_status_description_var === 'Cancelado pedido usuário'" 
            @click="goToDetail(props.item)" icon>
        <v-icon>mdi-magnify-expand</v-icon>
      </v-btn>
    </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      tickets: [],
      headers: [],
      filter: {
        Customer_Id: '',
        Start_Date: '',
        End_Date: '',
        Order_Status: '',
        Cpf_Client: '' 
      },
      orderStatusOptions: [
        { label: 'Aguardando Pagamento', value: '2' },
        { label: 'Pago', value: '3' },
        { label: 'Cancelado A Pedido Usuário', value: '4' },
        { label: 'Cancelado Tempo Expirado', value: '5'},
        { label: 'Cancelado Erro Reserva', value: '9'}
      ],
      menu: false,
      menu2: false
    };
  },
    methods: {
      async getListTickets() {
        localStorage.setItem('filterStartDate', this.filter.Start_Date);
        localStorage.setItem('filterEndDate', this.filter.End_Date);
        localStorage.setItem('filterOrderStatus', JSON.stringify(this.filter.Order_Status));
        localStorage.setItem('filterCpfClient', this.filter.Cpf_Client);
        this.filter.Customer_Id = localStorage.getItem("customer_Id");

        await this.$store.dispatch('ListTickets', this.filter); 
        this.tickets = this.getTickets; 
      },
      goToDetail(item) {
        this.$router.push({ name: "ticket-details", params: { id: item.order_id_bi, ticketDataProp: item } });
      },
      
      initializeHeaders(){
        this.headers = [
          {
            text: this.$t("ListTicketsHeaders.order_id_bi"),
            value: "order_id_bi",
            align: "center",
            class: "header",
            sortable: false,            
          },
          {
            text: this.$t("ListTicketsHeaders.client_name_var"),
            value: "client_name_var",
            align: "center",
            class: "header"
          },
          {
            text: this.$t("ListTicketsHeaders.client_document_var"),
            value: "client_document_var",
            align: "center",
            class: "header"
          },
          { 
            text: this.$t("ListTicketsHeaders.origin_destination"),
            value: "origin_destination",
            align: "center",
            class: "header"
          },
          {
            text: this.$t("ListTicketsHeaders.order_status_description_var"),
            value: "order_status_description_var",
            align: "center",
            class: "header",
          },
          {
            text: this.$t("ListTicketsHeaders.date_hour_travel"),
            value: "date_hour_travel",
            align: "center",
            class: "header"
          },
          {
            text: this.$t("ListTicketsHeaders.total_value_dec"),
            value: "total_value_dec",
            align: "center",
            class: "header",
            sortable: false,
          },
          {
            text: this.$t("ListTicketsHeaders.detailTicket"),
            value: "detailTicket",
            align: "center",
            class: "header",
          }
        ]
      },
      clearFilters() {      
        for (let key in this.filter) {
          this.filter[key] = '';
        }      
        localStorage.setItem('filterStartDate', this.filter.Start_Date);
        localStorage.setItem('filterEndDate', this.filter.End_Date);
        localStorage.setItem('filterOrderStatus', this.filter.Order_Status);
        localStorage.setItem('filterCpfClient', this.filter.Cpf_Client);
        this.filterTickets();
      },
      loadFiltersFromLocalStorage() {   
        this.filter.Start_Date = localStorage.getItem('filterStartDate') || '';
        this.filter.End_Date = localStorage.getItem('filterEndDate') || '';
        this.filter.Order_Status = localStorage.getItem('filterOrderStatus') || '';
        this.filter.Cpf_Client = localStorage.getItem('filterCpfClient') || '';
        this.filter.Order_Status = JSON.parse(this.filter.Order_Status)
      }
    },
    computed:{
      ...mapGetters(['getTickets']),    
      ticketsWithCombinedColumns() {
        return this.tickets.map(ticket => ({
          ...ticket,
          origin_destination: `${ticket.departure} / ${ticket.arrrival}`,
          date_hour_travel: `${ticket.travel_dt} / ${ticket.travel_hour}`
        }));
      }
    },
    async created() {
      this.initializeHeaders();
      this.loadFiltersFromLocalStorage();
    }
  }
</script>

<style scoped>


.filter-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-wrapper {
  background-color: white;
  border-radius: 10px; 
  padding: 20px;
}

.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.filter-row h1 {
  margin: 0;
}

.filter-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.v-data-table >>> .v-data-table-header th {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  padding: 6px;
}
</style>