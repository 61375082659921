<template>
    <div v-if="this.show" class="modal">
      <div class="modal-content" scrolling="auto">
        <div v-for="url in imageUrls" :key="url"  >
          <a :href="url" @click.prevent="openInNewTab(url)" download class="download-link">
          {{ getFileName(url) }} 
        </a>
        </div>
        <div class="modal-buttons">
          <button @click="$emit('close')">Fechar</button>
        </div>        
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean
      },
      imageUrls: {
        type: Array
      }
    },
    data() {
      return {
        isLoading: true,
        error: false,
      };
    },
    methods: {
      getFileName(url) {
        const parts = url.split('/');
        return parts[parts.length - 1]; 
      },
      openInNewTab(url) {
        window.open(url, '_blank');
      }
  }
  };
  </script>
  
  <style scoped>
  .modal { overflow:auto; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content { overflow:auto; 
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
  }
  


.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .modal-buttons button {
    margin-left: 10px; 
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .modal-buttons button:first-child { 
    background-color: #146a40; 
    color: white;
  }

  .download-link {
  display: block;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-decoration: none; 
  color: #333;
  margin: 5px;
}
</style>